<template>
    <div>
        <potential-applicants 
            :isProgram="true" 
            :currentStatus="currentStatus" />
    </div>
</template>

<script>
import PotentialApplicants from "@views/supplier/programs/kof/PotentialApplicants.vue";
export default {
    components: {
        PotentialApplicants
    },
    data() {
        return {
            currentStatus: "all"
        }
    }
}
</script>

<style>

</style>